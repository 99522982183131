import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { FIREBASE_AUTH } from '../config/firebaseConfig';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';

const AdminPortal = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState(null);
    const [imageFile, setImageFile] = useState(null); // For image upload
    const [imageName, setImageName] = useState('');   // Image alt text
    const [serviceData, setServiceData] = useState({
        name: '',
        description: '',
    });
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const handleLogIn = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(FIREBASE_AUTH, email, password);
            console.log('logged in')
            // navigate('/admin'); // Redirect to AdminPortal after successful login
            console.log('logged in')
        } catch (error) {
            console.error('Error during login:', error);
        }
    };
    return (
        <div className=' flex flex-col items-center justify-center h-screen bg-accent-blue'>
            <div className='p-8 bg-light-gray rounded-xl shadow-xl'>
                <p className='my-8' > Please log in as an admin.</p>
                <form onSubmit={handleLogIn} className='flex flex-col justify-center items-center'>
                    <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} className='p-1 w-min border-2 rounded-xl mb-8' />
                    <input
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        className='p-1 w-min border-2 rounded-xl'
                    />
                    <button type="submit" className='mt-8 hover:text-gray'>Login</button>
                </form>
            </div>

        </div >
    )
}

export default AdminPortal